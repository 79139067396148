import React from "react";
import OurServiceSectionCard from "./OurServiceSectionCard";

const OurServiceSection = () => {
  return (
    <div
      className="pl-[10%] pt-[3%] pr-[10%] pb-[3%] text-center bg-[#FFF6E7]"
      id="blog_section"
    >
      <div>
        <h1 className="text-[2.4rem] font-semibold text-[#000]">
          Our Services
        </h1>
        {/* <p
          className="text-[#5F5F5F] break-words text-[1.2rem] mt-2 pl-10 pr-10
          "
        >
          {" "}
          Sell online, in person, and around the world with the marketing tools,
          social integrations, and sales channels you need to get your products
          in front of customers.
        </p> */}
      </div>
      <div className="">
        <OurServiceSectionCard />
      </div>
    </div>
  );
};

export default OurServiceSection;
