import React, { useState } from "react";
import BlogShowingModal from "./BlogShowingModal";

function Card({ title, text, setShowModal, setpageContents, contentNumber }) {
  return (
    <div
      className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3 text-left cursor-pointer hover:opacity-[.8]"
      onClick={() => {setShowModal(true)
      setpageContents(contentNumber);}}
    >
      <div className="overflow-hidden rounded-lg">
        <img
          alt="Placeholder"
          className="block h-auto w-full rounded-lg"
          src="https://fastly.picsum.photos/id/370/600/400.jpg?hmac=YAo_uHCqZIxjiJ5-NslMBFipudEiveuW4nFoziTimUI"
        />

        <header className="">
          <h1 className="text-[.8rem] mt-4 text-[#717070]">{title}</h1>
          <p className="text-grey-darker font-bold text-[1.2rem] mt-2 text-gray-700">
            {text}
          </p>
        </header>
      </div>
    </div>
  );
}

function BlogSectionCard() {
  const contents = {
    1: "Customer activity can be easily tracked with one click",
    2: "Customer activity can be easily tracked with one click",
  };
  const [showModal, setShowModal] = useState(false);
  const [pageContents, setpageContents] = useState(0);

  return (
    <div className="container my-2 mx-auto px-4 md:px-12">
      <BlogShowingModal
        showModal={showModal}
        setShowModal={setShowModal}
        pageContents={contents[pageContents]}
      />
      <div className="flex flex-wrap -mx-1 lg:-mx-4 justify-center">
        <Card
          title={"November 25, 2022"}
          text={"Customer activity can be easily tracked with one click"}
          setShowModal={setShowModal}
          setpageContents={setpageContents}
          contentNumber={1}
        />
        <Card
          title={"November 25, 2022"}
          text={"Customer activity can be easily tracked with one click"}
          setShowModal={setShowModal}
          setpageContents={setpageContents}
          contentNumber={2}
        />
      </div>
    </div>
  );
}

export default BlogSectionCard;
