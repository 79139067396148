import React from "react";
import MarketingMade from "../../../assets/ManageAndKeep.png";
import { AiFillCheckCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

const ManageAndKeepSection = () => {
  return (
    <div className="bg-[#fff] pl-[18%] pt-[8%] lg:pt-[5%] pr-[10%] pb-[5%] flex lg:flex-row flex-col justify-between ">
      <div>
        <img
          className="w-[100%] h-[100%]"
          src={MarketingMade}
          alt="Add Or Manage Products"
        ></img>
      </div>
      <div className="lg:w-[50%]">
        <h1 className="text-[2.4rem] font-semibold text-[#000]">
          Tracking and Reporting
        </h1>
        <p
          className="text-[#5F5F5F] text-[1.2rem] mt-2 w-[80%] break-words
          "
        >
          {" "}
          Provides valuable insights into your sales performance, customer
          behavior, and website traffic through BeeCommerce&#39;s tracking and
          reporting features. Use this data to make informed business decisions
          and optimize your marketing strategies.
        </p>
        <div>
          <div className="flex items-center text-[1.3rem] mt-3">
            <AiFillCheckCircle className="text-[#7367F0] text-[1.5rem]  mr-3" />
            <p>Order Management</p>
          </div>
          <div className="flex items-center text-[1.3rem] mt-3">
            <AiFillCheckCircle className="text-[#7367F0] text-[1.5rem] mr-3" />
            <p>Order Tracking</p>
          </div>
          <div className="flex items-center text-[1.3rem] mt-3">
            <AiFillCheckCircle className="text-[#7367F0] text-[1.5rem] mr-3" />
            <p>Invoice Management</p>
          </div>
        </div>
        {/* <div className="mt-8">
          <Link className=" text-[#7367F0] text-[1.3rem]">
            Get Started &rarr;
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default ManageAndKeepSection;
