import { FaInstagram } from "react-icons/fa";
import { AiFillLinkedin, AiOutlineTwitter } from "react-icons/ai";
import { HiOutlineMail } from "react-icons/hi";
// import rashed from "../../../assets/rashed.jpeg";
import rashedBgRem from "../../../assets/rashed-removebg-preview.png";
import sagor from "../../../assets/sagor.png";
import nafiza from "../../../assets/nafizarm.png";
// import saif from "../../../assets/saif.jpeg";
import saif from "../../../assets/saif.png";

const TeamSection = function () {
  return (
    <div id="team_section">
      {/* component */}
      {/* MDI Icons */}

      {/* Page Container */}
      <div className="flex items-center justify-center pt-[10rem] pb-[10rem] bg-white py-4">
                  <h1 className="text-gray-900 text-4xl font-bold mb-2">
                    Management Team
                  </h1>
      
      </div>
    </div>
  );
};

export default TeamSection;
