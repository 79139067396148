import React from "react";
import HeroHome from "../components/Home/Hero/HeroHome";
import AboutUsSection from "../components/Home/AboutUs/AboutUsSection";
import SellBeeFooter from "../components/SellBeeFooter";
import TeamSection from "../components/Home/Team/TeamSection";
import OurServiceSection from "../components/Home/OurService/OurServiceSection";
import ContactUsSection from "../components/Home/ContactUs/ContactUsSection";
// import PartnersSection from "../components/Home/Partners/PartnersSection";

const HomePage = () => {
  return (
    <div>
      <HeroHome />
      <AboutUsSection />
      <OurServiceSection />
      <TeamSection />
      {/* <PartnersSection /> */}
      <ContactUsSection />
      <SellBeeFooter />
    </div>
  );
};

export default HomePage;
