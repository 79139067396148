import React from "react";
import { GetInTouchArea } from "./GetInTouchArea";
import SendMessageArea from "./SendMessageArea";

const ContactUsSection = () => {
  return (
    <div
      className="flex p-2 bg-[#cac6c657] flex-col md:flex-row justify-center py-[5%]"
      id="contact_us"
    >
      <SendMessageArea />
      {/* <GetInTouchArea /> */}
    </div>
  );
};

export default ContactUsSection;
