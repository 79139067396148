import React from "react";
import Hero from "../components/BeeCommerce/Hero/Hero";
import "./home.css";
import BetterShopping from "../components/BeeCommerce/BetterShopping/BetterShopping";
import AddOrManageSection from "../components/BeeCommerce/AddOrManage/AddOrManageSection";
import ManageAndKeepSection from "../components/BeeCommerce/MangeAndKeep/ManageAndKeepSection";
import MarketingMadeSection from "../components/BeeCommerce/MarketingMade/MarketingMadeSection";
import OfferStunningSection from "../components/BeeCommerce/OfferStunning/OfferStunningSection";
import EasyForCustomerSection from "../components/BeeCommerce/EasyForCustomer/EasyForCustomerSection";
import PricingSection from "../components/BeeCommerce/PricingSection/PricingSection";
import Footer from "../components/Footer";
import BlogSection from "../components/BeeCommerce/BlogSection/BlogSection";
import { FeatureListSection } from "../components/BeeCommerce/FeaturesList/FeatureListSection";
import ContactUsSection from "../components/Home/ContactUs/ContactUsSection";

const BeeCommercePage = () => {
  return (
    <div>
      <Hero />
      <BetterShopping />
      <AddOrManageSection />
      <ManageAndKeepSection />
      <MarketingMadeSection />
      <OfferStunningSection />
      {/* <EasyForCustomerSection /> */}
      {/* <PricingSection /> */}
      {/* <FeatureListSection /> */}
      <BlogSection />
      <ContactUsSection />

      <Footer />
    </div>
  );
};

export default BeeCommercePage;
