import React from "react";
import OfferStunning from "../../../assets/OfferStunning.png";
import { AiFillCheckCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

const OfferStunningSection = () => {
  return (
    <div className="bg-[#fff] pl-[18%] pt-[8%] lg:pt-[5%] pr-[10%] pb-[5%] flex lg:flex-row flex-col justify-between ">
      <div>
        <img
          className="w-[100%] h-[100%]"
          src={OfferStunning}
          alt="Add Or Manage Products"
        ></img>
      </div>
      <div className="lg:w-[50%]">
        <h1 className="text-[2.4rem] font-semibold text-[#000]">
          Control and Ownership
        </h1>
        <p
          className="text-[#5F5F5F] text-[1.2rem] mt-2 w-[80%] break-words
          "
        >
          {" "}
          BeeCommerce acts as a long-term asset for your business. It provides a
          stable online presence that you can build upon and expand over time.
          Unlike social media platforms that are subject to algorithm changes
          and limitations, a website gives you greater control and ownership of
          your online presence.
        </p>
        <div>
          <div className="flex items-center text-[1.3rem] mt-3">
            <AiFillCheckCircle className="text-[#7367F0] text-[1.5rem]  mr-3" />
            <p>Own Brand identity.</p>
          </div>
          <div className="flex items-center text-[1.3rem] mt-3">
            <AiFillCheckCircle className="text-[#7367F0] text-[1.5rem] mr-3" />
            <p>Control over products and campaigns.</p>
          </div>
          <div className="flex items-center text-[1.3rem] mt-3">
            <AiFillCheckCircle className="text-[#7367F0] text-[1.5rem] mr-3" />
            <p>Proper customer data.</p>
          </div>
        </div>
        {/* <div className="mt-8">
          <Link className=" text-[#7367F0] text-[1.3rem]">
            Get Started &rarr;
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default OfferStunningSection;
