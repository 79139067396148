import React from "react";
import AboutUsCard from "./AboutUsCard";

const AboutUsSection = () => {
  return (
    <div
      className="pl-[10%] pt-[5%] pr-[10%] pb-[5%] bg-[#cac6c624]"
      id="about_section"
    >
      <div>
        <h1 className="text-[2.2rem] font-semibold text-[#000]">About Us</h1>
        {/* <p
          className="text-[#5F5F5F] break-words text-[1.2rem] mt-2 pr-8
          "
        >
          {" "}
          Sell online, in person, and around the world with the marketing tools,
          social integrations, and sales channels you need to get your products
          in front of customers.
        </p> */}
      </div>
      <div className="">
        <AboutUsCard />
      </div>
    </div>
  );
};

export default AboutUsSection;
