import React from "react";
import addOrManage from "../../../assets/addOrManage.png";
import { AiFillCheckCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

const AddOrManageSection = () => {
  return (
    <div>
      <div className="bg-[#faf9f9] text-center">
        <h1 className="text-[2.4rem] font-semibold text-[#000] pt-4">
          Benefits
        </h1>
      </div>
      <div
        className="bg-[#faf9f9] pl-[18%] pt-[5%] pr-[10%] pb-[5%] flex lg:flex-row flex-col"
        id="section_benifites"
      >
        <div className="lg:w-[50%]">
          <h1 className="text-[2.4rem] font-semibold text-[#000]">
            Expanded Product Display
          </h1>
          <p
            className="text-[#5F5F5F] text-[1.2rem] mt-2 w-[90%] break-words
            "
          >
            {" "}
            BeeCommerce provides ample space to showcase your entire product
            range, with detailed descriptions and high-quality images. Allows
            customers to explore your products comprehensively, increasing their
            understanding and likelihood of making a purchase.
          </p>
          <div>
            <div className="flex items-center text-[1.3rem] mt-3">
              <AiFillCheckCircle className="text-[#7367F0] text-[1.5rem]  mr-3" />
              <p>Manage Products</p>
            </div>
            <div className="flex items-center text-[1.3rem] mt-3">
              <AiFillCheckCircle className="text-[#7367F0] text-[1.5rem] mr-3" />
              <p>Inventory Management</p>
            </div>
            <div className="flex items-center text-[1.3rem] mt-3">
              <AiFillCheckCircle className="text-[#7367F0] text-[1.5rem] mr-3" />
              <p>Price Management</p>
            </div>
          </div>
          {/* <div className="mt-8">
            <Link className=" text-[#7367F0] text-[1.3rem]">
              Get Started &rarr;
            </Link>
          </div> */}
        </div>
        <div>
          <img
            className="w-[100%] h-[100%]"
            src={addOrManage}
            alt="Add Or Manage Products"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default AddOrManageSection;
