import React from "react";
import HeroHeader from "./HeroHeader";
import NavBarSellBee from "../../BeeCommerce/NavBarSellBee";
const HeroHome = () => {
  return (
    <div className="bg-[#fff]">
      <NavBarSellBee />
      <div>
        <HeroHeader />
      </div>
    </div>
  );
};

export default HeroHome;
