import { Button, Input } from "@material-tailwind/react";
import React from "react";
import { Link } from "react-router-dom";

const SendMessageArea = () => {
  return (
    <form className="pl-11">
      <div>
        <h1 className="text-[2.2rem] font-semibold">Contact Us</h1>
        {/* <p className="w-[80%] mt-4 text-[#000000ab]">
         Contact Us
        </p> */}
      </div>
      <div className="flex w-[80%] mt-8 mb-2 flex-col md:flex-row">
        <div className="mr-4">
          <Input
            type="text"
            id=""
            name="Email"
            placeholder="Your Email"
            className="!bg-[#fff]  p-4 !text-[1.2rem] !outline-none"
            required
          />
        </div>
        <div className="mr-4 md:mr-0">
          <Input
            type="number"
            id=""
            name="Phone"
            placeholder="Phone Number"
            className="!bg-[#fff]  p-4 !text-[1.2rem]  !outline-none !appearance-none mt-3 md:mt-0"
            required
          />
        </div>
      </div>
      <div>
        <textarea
          class="resize-none h-[10rem] w-[80%] p-4 mt-4 !text-[1.2rem] !outline-none"
          placeholder="Your Message"
        ></textarea>
      </div>
      <div className="float-left mt-2 ">
        <Button
          variant="gradient"
          size="sm"
          type="submit"
          className=" lg:inline-block bg-[#7367F0] text-[#fff] rounded-md font-normal hover:bg-[#7267f0c5] px-8 py-5"
        >
          <Link style={{ textTransform: "none" }}>Send Message</Link>
        </Button>
      </div>
    </form>
  );
};

export default SendMessageArea;
