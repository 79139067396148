import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import NewsLatter from "./NewsLatter";
import HeroCard from "../Hero/HeroCard";
import { Link } from "react-router-dom";
import { AiFillCaretRight } from "react-icons/ai";
import SellBeeHero from "../../../assets/SellBeeHero.png";

export default function HeroHeader() {
  return (
    <Card className="w-[100%] grid grid-cols-1 lg:grid-cols-2 gap-8 mt-[7%] md:ml-[9%] bg-[#fff] p-2">
      <CardHeader
        color="blue"
        className="relative pl-4 md:pt-9"
        style={{ boxShadow: "none" }}
      >
        <Typography
          variant="h5"
          className="mb-2 flex flex-col text-[40px] md:text-[64px] text-[#7367F0]"
        >
          Revolutionize Your
          <span>
            Business with <br></br>
            <span className="text-[#000]">SellBee</span>
          </span>
        </Typography>
        <Typography className="text-[#5F5F5F] !text-[1.3rem] break-words w-[80%]">
          Make Your Ecommerce Dreams a Reality.
        </Typography>
        {/* <NewsLatter /> */}
        {/* <div className="mt-8 flex items-center">
          <Button
            variant="gradient"
            size="sm"
            className=" lg:inline-block bg-[#7367F0] text-[#fff] rounded-md font-normal hover:bg-[#7267f0c5] px-4 py-3"
          >
            <Link style={{ textTransform: "none" }}>Get Started</Link>
          </Button>
          <Button
            variant="gradient"
            size="sm"
            className=" lg:inline-block  text-[#000] rounded-md font-bold hover:bg-[#7267f013] px-4 py-3"
          >
            <Link
              style={{ textTransform: "none" }}
              className="flex items-center text-[1.1rem]"
            >
              <AiFillCaretRight className="mr-2" /> Watch Video
            </Link>
          </Button>
        </div> */}
      </CardHeader>

      <CardBody className="p-[0rem] mb-[0rem] lg:mt-[-10%] w-full">
        <div className="ml-[-18%]">
          {/* Your card content goes here */}
          <img
            src={SellBeeHero}
            className="w-[80%] lg:right-[30%]"
            alt="SellBee Sales"
          ></img>
        </div>
      </CardBody>
    </Card>
  );
}
