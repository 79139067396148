import { Avatar, Button, Typography } from "@material-tailwind/react";
import React, { useState } from "react";
import logo1 from "../../../assets/logo1.png";
import { Link, useNavigate } from "react-router-dom";

function Card({ title, text, text2, logoName }) {
  const navigate = useNavigate();

  const openOtherpage = (title) => {
    if (title === "RealX") {
      window.open("/realx", "_blank");
    } else {
      window.open("/beecommerce", "_blank");
    }
  };

  return (
    <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3 text-left ">
      <div
        className="overflow-hidden rounded-lg p-6 bg-[#fff] pb-10 cursor-pointer h-[20rem] hover:shadow-xl transition"
        onClick={() => openOtherpage(title)}
      >
        <div>
          <Typography
            as="a"
            href="#"
            variant="small"
            className="mr-4 cursor-pointer py-1.5 font-normal"
          >
            <span className="flex items-center">
              <Avatar className="w-[2rem] mr-2" src={logo1} alt="avatar" />
              <p className="font-semibold text-[1.2rem] text-[#000]">
                {logoName}
              </p>
            </span>
          </Typography>
        </div>
        <header className="">
          <h1 className="mt-4 text-[#000] text-[2rem] font-bold">{title}</h1>
          {/* <p className="text-grey-darker font-bold text-[1.2rem]  text-gray-700">
            {text}
          </p> */}

          <div className="mt-4">
            <p className="text-grey-darker text-[1rem] mt-2 text-gray-700 w-[17rem]">
              {text2}
            </p>
            {/* {title === "RealX" ? (
              <Link to="/realx">
                <button className="text-[#000] mt-4 text-[1.1rem] hover:text-[#7367F0] transition">
                  Explore Now &#10132;
                </button>
              </Link>
            ) : (
              <Link to="/beecommerce">
                <button className="text-[#000] mt-4 text-[1.1rem] hover:text-[#7367F0] transition">
                  Explore Now &#10132;
                </button>
              </Link>
            )} */}
          </div>
        </header>
      </div>
    </div>
  );
}

function OurServiceSectionCard() {
  return (
    <div className="container my-2 mx-auto px-4 md:px-12">
      <div className="flex flex-wrap -mx-1 lg:-mx-4 justify-center">
        <Card
          title={"RealX"}
          text={"See Real, Be Real."}
          text2={
            "Elevate your online shopping experience with Augmented Reality product visualization. No installation requires. Scan and get the real experience of product viewing."
          }
          logoName={"RealX"}
        />
        <Card
          title={"BeeCommerce"}
          text={"E-Commerce Easy With BeeCommerce"}
          text2={
            "Streamline your online presence effortlessly with your own Website. Get online quickly and easily, without any technical hassle, and start showcasing your products and services to a global audience."
          }
          logoName={"BeeCommerce"}
        />
      </div>
    </div>
  );
}

export default OurServiceSectionCard;
