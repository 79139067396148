import React from "react";

function Card({ title, text }) {
  return (
    <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3 text-center">
      <div className="overflow-hidden rounded-lg">
        {/* <a href="#">
          <img
            alt="Placeholder"
            className="block h-auto w-full rounded-lg"
            src="https://fastly.picsum.photos/id/370/600/400.jpg?hmac=YAo_uHCqZIxjiJ5-NslMBFipudEiveuW4nFoziTimUI"
          />
        </a> */}

        <header className="">
          <h1 className="text-lg font-bold mt-4 text-[1.4rem]">
            <a className="no-underline hover:underline text-black" href="#">
              {title}
            </a>
          </h1>
          <p className="text-grey-darker text-sm text-[1rem] mt-2 text-gray-700 px-6">
            {text}
          </p>
        </header>
      </div>
    </div>
  );
}

function BetterShoppingCard() {
  return (
    <div className="container my-2 mx-auto px-4 md:px-12">
      <div className="flex flex-wrap -mx-1 lg:-mx-4">
        <Card
          title={"Easy Product Showcasing"}
          text={
            "Engage customers with an attractive and user-friendly front-end interface to display your products and brand."
          }
        />
        <Card
          title={"Product Management"}
          text={
            "Easily add, edit, and update product information, categories, and other website content through the intuitive back-end system."
          }
        />
        <Card
          title={"Category Add/Remove"}
          text={
            "Organize your products into categories for easy navigation and browsing."
          }
        />
        <Card
          title={"Order Management"}
          text={
            "Effortlessly manage orders, track their status, process payments, and handle order fulfillment."
          }
        />{" "}
        <Card
          title={"Tracking and Reporting:"}
          text={
            "Gain insights into your sales performance and customer behavior through comprehensive tracking and reporting features."
          }
        />{" "}
        <Card
          title={"Email and SMS Promotions"}
          text={
            "Utilize the built-in email and SMS promotion service to send targeted campaigns and discounts to your customers, driving conversions and customer engagement."
          }
        />{" "}
        <Card
          title={"Mobile-Responsive"}
          text={
            "Ensure a seamless shopping experience across devices with a mobile-responsive website design."
          }
        />{" "}
        <Card
          title={"Multiple Templates"}
          text={
            "Choose from a range of customizable templates to create a unique and personalized website that aligns with your brand identity."
          }
        />
      </div>
    </div>
  );
}

export default BetterShoppingCard;
