import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Tooltip,
} from "@material-tailwind/react";

export default function HeroCard({ price, name, image }) {
  return (
    <Card className="w-[10rem] h-[16rem] bg-[#fff] !mb-[0rem]  drop-shadow-2xl rounded-2xl mr-4 lg:mr-[4rem]">
      <CardHeader floated={false} className=" mt-[0rem]">
        <img src={image} alt="" className="w-[10rem] h-[12rem] rounded-t-2xl" />
      </CardHeader>
      <CardBody className="text-center !mb-[0rem]">
        <Typography variant="h4" color="blue-gray" className="">
          {name}
        </Typography>
        {/* <Typography color="blue" className="font-medium" textGradient>
          BDT {price}
        </Typography> */}
      </CardBody>
    </Card>
  );
}
