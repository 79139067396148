import React from "react";
import BetterShoppingCard from "./BetterShoppingCard";

const BetterShopping = () => {
  return (
    <div
      className="pl-[10%] pt-[5%] pr-[10%] pb-[5%] text-center"
      id="beecom_section"
    >
      <div>
        <h1 className="text-[2rem] font-semibold text-[#000]">
          Why BeeCommerce
        </h1>
        {/* <p
          className="text-[#5F5F5F] break-words w-[80%] text-[1.2rem] mt-2
          "
        >
          {" "}
          The thing is, there are hundreds of tools to choose from, which can be
          costly<br></br>and time consuming to go through
        </p> */}
      </div>
      <div className="">
        <BetterShoppingCard />
      </div>
    </div>
  );
};

export default BetterShopping;
