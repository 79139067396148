import React from "react";

const ExternalPage = ({ url }) => {
  return (
    <div className="w-[100%] h-[100%]">
      <iframe title="external-page" className="w-[100%] h-[100vh]" src={url} />
    </div>
  );
};

export default ExternalPage;
