import { useState, useEffect } from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
  Avatar,
} from "@material-tailwind/react";
import logo1 from "../../assets/logo1.png";
import { Link } from "react-scroll";
import { Link as LinkRouter } from "react-router-dom";

export default function NavArea() {
  const [openNav, setOpenNav] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const closeMenu = () => setIsMenuOpen(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const profileMenuItems = [
    {
      label: "My Profile",
    },
    {
      label: "Edit Profile",
    },
    {
      label: "Inbox",
    },
    {
      label: "Help",
    },
    {
      label: "Sign Out",
    },
  ];

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6 text-[#000] ">
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal text-[1rem]"
      >
        <LinkRouter to="/" className="flex items-center">
          Home
        </LinkRouter>
      </Typography>
      {/* <div class="dropdown">
        <Typography
          className=" p-1 font-normal cursor-pointer text-[1rem] md:mb-1"
          variant="small"
          color="blue-gray"
        >
          BeeCom <span className="text-[1.4rem]">&#8964;</span>
        </Typography>
        <button class="">Dropdown</button>
        <div class="dropdown-content">
          <LinkRouter to="/realx">Real X</LinkRouter>
          <a href="#">Beecomm</a>
          <a href="#">Link 3</a>
        </div>
      </div> */}

      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal text-[1rem] cursor-pointer"
      >
        <Link
          activeClass="active"
          to="beecom_section"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Why BeeCommerce
        </Link>
      </Typography>

      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal text-[1rem] cursor-pointer"
      >
        <Link
          activeClass="active"
          to="section_benifites"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Benefits
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal text-[1rem] cursor-pointer"
      >
        <Link
          activeClass="active"
          to="blog_section"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Blog
        </Link>
      </Typography>
    </ul>
  );

  return (
    <Navbar
      className="mx-auto max-w-screen-xl py-2 px-4 lg:px-8 lg:py-4 border-spacing-0 bg-[#FFF6E7]"
      style={{ border: "none" }}
    >
      <div className="container mx-auto flex items-center justify-between">
        <Typography
          as="a"
          href="#"
          variant="small"
          className="mr-4 cursor-pointer py-1.5 font-normal"
        >
          <LinkRouter to="/beecommerce" className="flex items-center">
            <Avatar className="w-[80%] mr-2" src={logo1} alt="avatar" />
            <p className="font-semibold text-[1.2rem] text-[#000]">
              BeeCommerce
            </p>
          </LinkRouter>
        </Typography>
        <div className="hidden lg:block">{navList}</div>
        <a href="https://mysellbee.com/login" target="_blank" rel="noreferrer">
          <Button
            variant="va"
            size="sm"
            className="hidden lg:inline-block bg-[#7367F0] text-[#fff] rounded-md font-normal hover:bg-[#7267f0c5]
]"
          >
            Log In / Register
          </Button>
        </a>{" "}
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden text-[#000]"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </IconButton>
      </div>
      <MobileNav open={openNav} className="overflow-visible lg:overflow-hidden">
        <div className="container mx-auto ">
          {navList}
          <a
            href="https://mysellbee.com/login"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              variant="va"
              size="sm"
              className="lg:inline-block bg-[#7367F0] text-[#fff] rounded-md font-normal hover:bg-[#7267f0c5]
]"
            >
              Log In / Register
            </Button>
          </a>{" "}
          {/* <button class="">Dropdown</button> */}
        </div>
      </MobileNav>
    </Navbar>
  );
}
