import React from "react";
import { BsFillFlagFill, BsFillHeartFill } from "react-icons/bs";
import { AiFillStar } from "react-icons/ai";

import { useState } from "react";

function Card({ point, icon, title, text }) {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => setShowMore(!showMore);

  return (
    <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
      <div
        className={`overflow-hidden rounded-lg flex  flex-col p-[1rem] lg:p-[4rem] shadow-xl ${
          point === 1 ? "bg-[#FFF6E7]" : "bg-[#fff]"
        }`}
      >
        <div>
          <div>
            <div className="text-[#7367F0] text-[1.6rem]">{icon}</div>
          </div>
        </div>
        <header className="">
          <h1 className="text-lg font-bold mt-4 text-[1.6rem]">{title}</h1>
          <p className="text-grey-darker text-sm text-[1.1rem] mt-2 text-gray-700 pr-2">
            {showMore ? text : `${text.slice(0, 150)}...`}
            {text.length > 150 && (
              <button
                onClick={toggleShowMore}
                className="text-blue-500 hover:text-blue-700"
              >
                {showMore ? "See Less" : "See More"}
              </button>
            )}
          </p>
        </header>
      </div>
    </div>
  );
}

function AboutUsCard() {
  return (
    <div className="container my-2 mx-auto px-4 md:px-12">
      <div className="flex flex-wrap -mx-1 lg:-mx-4">
        <Card
          title={"Our Mission"}
          text={
            "Our mission at Sellbee is to empower SMEs and Brands with cutting-edge digital solutions, enabling them to amplify their online sales and create lasting customer connections. We strive to deliver innovative software products, including Web Tools, AR for e-commerce, and Social CRM, in a scalable and accessible Software-as-a-Service (SaaS) model. Through our dedication to excellence and customer- centric approach, we aim to be the go-to partner for businesses seeking to enhance their digital presence and achieve sustainable growth in the evolving marketplace."
          }
          icon={<BsFillFlagFill />}
          point={1}
          color="#4535AF"
        />
        <Card
          title={"Our Vision"}
          text={
            "At Sellbee, our vision is to revolutionize the digital sales landscape by providing SMEs and brands with the tools and technologies they need to thrive in the online marketplace. We envision a future where businesses can seamlessly leverage web tools, augmented reality for e-commerce, and social CRM to engage customers, optimize conversions, and drive revenue. Through our SaaS model, we aspire to democratize access to these transformative solutions, empowering organizations of all sizes to compete on a level playing field and achieve their sales objectives."
          }
          icon={<BsFillHeartFill />}
          point={2}
          color="#EF7A57"
        />
        <Card
          title={"Our Services"}
          text={
            "AR: Elevate your online shopping experience with augmented reality product visualization. No installation requires. Scan and get the real experience of product viewing. Web Tool: Streamline your online presence effortlessly with your own website. Get online quickly and easily, without any technical hassle, and start showcasing your products and services to a global audience."
          }
          icon={<AiFillStar />}
          point={3}
          color="#0ACF83"
        />
      </div>
    </div>
  );
}

export default AboutUsCard;
