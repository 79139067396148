import React from "react";
import MarketingMade from "../../../assets/MarketingMade.png";
import { AiFillCheckCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

const MarketingMadeSection = () => {
  return (
    <div className="bg-[#faf9f9] pl-[18%] pt-[5%] pr-[10%] pb-[5%] flex lg:flex-row flex-col">
      <div className="lg:w-[50%]">
        <h1 className="text-[2.4rem] font-semibold text-[#000]">
          Enhanced User Experience
        </h1>
        <p
          className="text-[#5F5F5F] text-[1.2rem] mt-2 w-[80%] break-words
          "
        >
          {" "}
          BeeCommerce offers a more seamless user experience compared to social
          media sales. You can optimize the navigation, search functionality,
          and user interface to make it easy for customers to find and explore
          products, leading to higher customer satisfaction and conversion
          rates. Also enabels easier and effective marketing to your clients.
        </p>
        <div>
          <div className="flex items-center text-[1.3rem] mt-3">
            <AiFillCheckCircle className="text-[#7367F0] text-[1.5rem]  mr-3" />
            <p>Email Marketing</p>
          </div>
          <div className="flex items-center text-[1.3rem] mt-3">
            <AiFillCheckCircle className="text-[#7367F0] text-[1.5rem] mr-3" />
            <p>Client Filtering</p>
          </div>
          <div className="flex items-center text-[1.3rem] mt-3">
            <AiFillCheckCircle className="text-[#7367F0] text-[1.5rem] mr-3" />
            <p>Select Clients Per Niche</p>
          </div>
        </div>
        {/* <div className="mt-8">
          <Link className=" text-[#7367F0] text-[1.3rem]">
            Get Started &rarr;
          </Link>
        </div> */}
      </div>
      <div>
        <img
          className="w-[100%] h-[100%]"
          src={MarketingMade}
          alt="Add Or Manage Products"
        ></img>
      </div>
    </div>
  );
};

export default MarketingMadeSection;
