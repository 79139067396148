import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Footer from "./components/Footer";
import BeeCommercePage from "./pages/BeeCommercePage";
import ScrollToTop from "./components/ScrollToTop";
import HomePage from "./pages/HomePage";
import ExternalPage from "./pages/ExternalPage";

const App = () => {
  // useEffect(() => {
  //   document.title = window.location.hostname.split(".")[0];
  // }, []);

  return (
    <Router>
      <ScrollToTop>
        {/* <TopNavbar />
        <Navbar /> */}
        <Routes>
          <Route index path="/BeeCommerce" element={<BeeCommercePage />} />
          <Route index path="/" element={<HomePage />} />
          <Route
            index
            path="/realx"
            element={<ExternalPage url="https://realxsellbee.netlify.app/" />}
          />
        </Routes>
        {/* <Footer /> */}
      </ScrollToTop>
    </Router>
  );
};

export default App;
