import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import NewsLatter from "./NewsLatter";
import HeroCard from "../Hero/HeroCard";
import { Link } from "react-router-dom";
import { AiFillCaretRight } from "react-icons/ai";
import checkout from "../../../assets/checkout.png";
import promotion from "../../../assets/Promotion.png";
import product from "../../../assets/Product.png";
import userManagement from "../../../assets/userManagement.png";

export default function HeroHeader() {
  return (
    <Card className="w-[100%] grid grid-cols-1 lg:grid-cols-2 gap-8 mt-[8%] pl-4 md:ml-[10%] bg-[#FFF6E7]">
      <CardHeader
        color="blue"
        className="relative"
        style={{ boxShadow: "none" }}
      >
        <Typography
          variant="h5"
          className="mb-2 flex flex-col text-[30px] md:text-[64px] text-[#000]"
        >
          E-Commerce,
          <span>
            Easy with <span className="text-[#7367F0]">BeeCommerce</span>
          </span>
        </Typography>
        <Typography className="text-[#5F5F5F] break-words w-[80%]">
          BeeCommerce is a one click readymade complete ecom website for you.
          Simply register, provide information, and instantly receive a fully
          customizable website tailored to your business needs. It erases
          technical hassle, and helps the SMEs to start showcasing your products
          and services to a global audience.
        </Typography>
        {/* <NewsLatter /> */}
        <div className="mt-8 flex items-center">
          {/* <Button
            variant="gradient"
            size="sm"
            className=" lg:inline-block bg-[#7367F0] text-[#fff] rounded-md font-normal hover:bg-[#7267f0c5] px-4 py-3"
          >
            <Link style={{ textTransform: "none" }}>Get Started</Link>
          </Button> */}
          {/* <Link
              style={{ textTransform: "none" }}
              className="flex items-center text-[1.1rem]"
            > */}
          {/* <AiFillCaretRight className="mr-2" /> Watch Video */}
          <a
            href="https://mysellbee.com/register?promo_code=SELLBEE"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              variant="gradient"
              size="md"
              style={{paddingLeft: "1.5rem", paddingRight: "1.5rem", paddingTop: "1.1rem", paddingBottom: "1.1rem", fontSize: "1.2rem"}}
              className=" lg:inline-block  bg-[#7367F0] text-[#fff] rounded-md font-bold hover:bg-[#7267f0b3] px-4 py-3"
            >
              Register
            </Button>
          </a>{" "}
          {/* </Link> */}
        </div>
      </CardHeader>

      <CardBody className="p-[0rem] mb-[0rem] lg:mt-[-10%] ">
        <div className="flex mb-4 lg:rotate-[-6deg]">
          <HeroCard price={200} name={"Product Showcase"} image={product} />
          <div className="lg:mt-[2rem]">
            <HeroCard price={200} name={"Easy Checkout"} image={checkout} />
          </div>
        </div>
        <div className="flex lg:rotate-[-6deg] lg:ml-[2rem]">
          <HeroCard price={200} name={"Targeted Marketing"} image={promotion} />
          <div className="lg:mt-[2rem]">
            <HeroCard
              price={200}
              name={"Workload Division"}
              image={userManagement}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
