import React from "react";
import NavArea from "../NavBar";
import HeroHeader from "./HeroHeader";
const Hero = () => {
  return (
    <div className="bg-[#FFF6E7] pb-6">
      <NavArea />
      <div>
        <HeroHeader />
      </div>
    </div>
  );
};

export default Hero;
