import React from "react";
import TabsList from "./Tabs";

export const FeatureListSection = () => {
  return (
    <div className="pl-[5%] pr-[5%] pt-[8%] pb-[5%] text-center">
      <div>
        <h1 className="text-[2.2rem] font-semibold text-[#000] pb-8">
          Benefits
        </h1>
      </div>
      <div className="text-left overflow-x-scroll">
        <TabsList />
      </div>
    </div>
  );
};
